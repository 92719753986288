<template>
  <div>
    <!--begin::customer-->
    <h3 class="card-label">
      {{ $t('debits.debits') }}
      <span class="text-muted pt-2 font-size-sm d-block"></span>
    </h3>

    <b-tabs content-class="mt-3" class="nav-custom-link">
      <b-tab :title="$t('debits.basic_information')" @click="toggleTab('basic_information')">

        <div class="row">
          <div class="col-md-6">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('debits.customer') }}<span class="text-danger">*</span></label>
                  <div class="form-group row mb-0">
                    <div class="col-sm-8">
                      <multiselect
                          id="customer_id"
                          v-model="customer"
                          :placeholder="$t('debits.customer')"
                          label="fullname"
                          track-by="id"
                          :options="customers"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getCustomers($event)">
                      </multiselect>
                      <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('debits.customer') }}.</span>
                    </div>
                    <div class="col-sm-4" v-if="!isDisabled">
                      <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>
                    </div>
                  </div>
                  <span v-if="validation && validation.customer_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.customer_id[0] }}
                                    </span>
                </div>
              </div>
            </div>

            <b-card no-body v-if="customer" class="mt-5">
              <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-50 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                  {{ $t('customer_data_details') }}
                </b-button>
              </b-card-header>

              <b-collapse id="accordion-50" accordion="my-accordion-1" role="tabpanel">
                <b-card-body class="row">
                  <div class="col-lg-12 mb-5">
                    <p><b>{{ $t('name') }}: </b> {{ customer.fullname }}</p>
                    <p><b>{{ $t('mobile') }}: </b> {{ customer.mobile }}</p>
                    <p><b>{{ $t('email') }}: </b> {{ customer.email }}</p>
                    <p><b>{{ $t('tax_no') }}: </b> {{ customer.tax_register }}</p>
                  </div>
                  <div class="col-md-12 mb-5">
                    <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                      <h6 class="my-auto text-white">{{ $t('customers.balances') }}</h6>
                    </div>
                    <table class="table table-row-bordered">
                      <thead>
                      <tr>
                        <th>{{ $t('customers.balance') }}</th>
                        <th>{{ $t('customers.debit') }}</th>
                        <th>{{ $t('customers.currency') }}</th>
                        <th>{{ $t('customers.last_validated_date') }}</th>
                        <th></th>
                      </tr>

                      </thead>
                      <tbody>
                      <template v-if="customer.balances && customer.balances.length > 0">
                        <tr v-for="(row, index) in customer.balances" :key="index">
                          <td>{{ row.debit }}</td>
                          <td>{{ row.debit }}</td>
                          <td>{{ row.currency_name }}</td>
                          <td>{{ row.last_validated_date }}</td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td colspan="4">{{ $t('no_balances') }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mt-5">
              <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                  {{ $t('accounts') }}
                </b-button>
              </b-card-header>

              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body class="row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('accounts') }}</label>
                    <div class="input-group">
                      <multiselect
                          v-model="account"
                          :placeholder="$t('accounts')"
                          label="label"
                          track-by="id"
                          :options="accounts"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getAccounts($event)">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/accounting/chart-accounts" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('accounts') }}.</span>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mt-5">
              <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-15 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                  {{ $t('applicable_taxes') }}
                </b-button>
              </b-card-header>

              <b-collapse id="accordion-15" accordion="my-accordion" role="tabpanel">
                <b-card-body class="row">
                  <div class="col-lg-12 mb-5" v-for="(taxes_rules, index) in taxes_rules_for_tax_setting" :key="'tax_rule'+index">
                    <b-form-checkbox :id="'checkbox-'+index" v-model="data.apply_tax_rules" :name="'checkbox-'+index" :value="{tax_rule_id: taxes_rules.id}">
                      {{ taxes_rules.name }}
                    </b-form-checkbox>
                  </div>


                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <div class="col-md-6">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('debits.debit_code') }}</label>
                  <input type="text" v-model="data.debit_code" disabled class="form-control" :class="validation && validation.debit_code ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.debit_code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.debit_code[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('debits.debit_date') }}</label>
                  <input type="date" v-model="data.debit_date" disabled class="form-control" :class="validation && validation.debit_date ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.debit_date" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.debit_date[0] }}
                                    </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('debits.issue_date') }}</label>
                  <input type="date" v-model="data.debit_issue" disabled class="form-control" :class="validation && validation.debit_issue ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.debit_issue" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.debit_issue[0] }}
                                    </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('debits.currency') }}</label>
                  <div class="input-group">
                    <select name="" id="currency_id" :disabled="isDisabled" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                      <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                    </select>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.currency_id[0] }}
                                    </span>
                </div>
                <!--                <div class="col-lg-6 mb-5">-->
                <!--                  <label>{{ $t('debits.inventory') }}<span class="text-danger">*</span></label>-->
                <!--                  <div class="input-group">-->
                <!--                    <multiselect v-model="inventory"-->
                <!--                                 :placeholder="$t('open_stock_items.inventory')"-->
                <!--                                 label="name"-->
                <!--                                 track-by="id"-->
                <!--                                 :options="inventories"-->
                <!--                                 :multiple="false"-->
                <!--                                 :disabled="isDisabled"-->
                <!--                                 :class="validation && validation.inventory_id ? 'is-invalid' : ''"-->
                <!--                                 :taggable="false"-->
                <!--                                 :show-labels="false"-->
                <!--                                 :show-no-options="false"-->
                <!--                                 :show-no-results="false">-->
                <!--                    </multiselect>-->
                <!--                    <div class="input-group-prepend">-->
                <!--                      <a class="btn btn-primary" href="/settings/inventories" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                  <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">-->
                <!--                                        {{ validation.inventory_id[0] }}-->
                <!--                                    </span>-->
                <!--                </div>-->
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('branch') }}</label>
                  <div class="input-group">
                    <multiselect v-model="branch"
                                 :placeholder="$t('branch')"
                                 label="name"
                                 track-by="id"
                                 :options="branches"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.branch_id[0] }}
                  </span>
                </div>

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('status') }}</label>
                  <select name="" id="f_status" v-model="data.status" type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                    <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                  </select>
                  <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.status[0] }}
                                    </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('is_apply_taxes_invoice') }}:</label>
                  <b-form-checkbox size="lg" :disabled="true" v-model="data.is_apply_taxes_debit" name="check-button" switch></b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row mt-5">
          <div class="col-md-12">
            <hr>
          </div>
          <div class="col-md-12">
            <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
              <h6 class="mt-2">{{ $t('debits.items') }}</h6>
              <div>
                <button type="button" class="btn btn-primary mr-1 ml-1" v-if="!isDisabled" @click="addItemRowToList(false)">{{ $t('add_more') }}</button>
                <button type="button" class="btn btn-primary" @click="addItemRowToList(true)">{{ $t('fetch_all_items') }}</button>
              </div>
            </div>
            <div class="table-responsive bg-white pb-5">
              <!--                    <table class="table table-row-bordered w-max-content" @keyup.enter="addItemRowToList" @keyup.46="removeItemRowFromList">-->
              <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList">
                <thead>
                <tr>
                  <th width="250px">{{ $t('items.item_details') }}</th>
                  <th width="140px">{{ $t('debits.unit_price') }}</th>
                  <th width="140px">{{ $t('credits.original_qty') }}</th>
                  <th width="140px">{{ $t('debits.unit') }}</th>
                  <th width="140px">{{ $t('debits.qty') }}</th>
                  <th width="140px" v-if="isApplyTaxesInvoice">{{ $t('debits.tax') }}</th>
                  <th width="200px">{{ $t('discount') }}</th>
                  <th width="80px" v-if="isApplyTaxesInvoice">{{ $t('debits.amount_tax') }}</th>
                  <th width="80px">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                  <th width="80px">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                  <th width="10px"></th>
                </tr>

                </thead>
                <tbody>
                <tr v-for="(row, index) in items_list" :key="index" :class="getClassValidationRepeaterByIndex(index)">

                  <td>
                    <input v-model="row.description" disabled type="text" :placeholder="$t('debits.description')" class="form-control">
                    <span v-if="validation && validation[`items_list.${index}.description`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items_list.${index}.description`][0] }}
                        </span>
                  </td>
                  <td>
                    <input v-model="row.unit_price" disabled @input="setValue(index)" type="number" class="form-control">
                    <span v-if="validation && validation[`items_list.${index}.unit_price`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items_list.${index}.unit_price`][0] }}
                        </span>
                  </td>
                  <td>
                    <input v-model="row.qty_basic" disabled type="number" class="form-control">
                  </td>
                  <td>
                    <multiselect v-model="row.unit" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('debits.unit')"
                                 :placeholder="$t('debits.unit')"
                                 label="name"
                                 track-by="id"
                                 :options="units"
                                 :disabled="true"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <span v-if="validation && validation[`items_list.${index}.unit`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items_list.${index}.unit`][0] }}
                        </span>
                  </td>
                  <td>
                    <input v-model="row.qty" @input="setValue(index)" type="number" class="form-control">
                    <span v-if="validation && validation[`items_list.${index}.qty`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items_list.${index}.qty`][0] }}
                        </span>
                  </td>
                  <!--                  <td><input v-model="row.tax_percentage" min="0" :disabled="!(row.item && row.item.taxes  && row.item.taxes.length > 0)" @input="setValue(index)" type="number" class="form-control"></td>-->
                  <td v-if="isApplyTaxesInvoice">
                    <multiselect v-model="row.tax_applied" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('sales_invoices.tax')"
                                 :placeholder="$t('sales_invoices.tax')"
                                 label="name"
                                 track-by="id"
                                 :options="taxes"
                                 :multiple="true"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @input="setValue(index)">
                      <!--                                 @search-change="getTaxes($event)">-->
                    </multiselect>
                  </td>
                  <!--                  <td><input v-model="row.tax_percentage" min="0" :disabled="true" @input="setValue(index)" type="number" class="form-control"></td>-->

                  <!--                        <td>-->
                  <!--                          <multiselect v-model="row.tax"-->
                  <!--                                       :placeholder="$t('debits.tax')"-->
                  <!--                                       label="name"-->
                  <!--                                       track-by="id"-->
                  <!--                                       :options="taxes"-->
                  <!--                                       :multiple="false"-->
                  <!--                                       :taggable="false"-->
                  <!--                                       :show-labels="false"-->
                  <!--                                       :show-no-options="false"-->
                  <!--                                       :show-no-results="false"-->
                  <!--                                       @search-change="getTaxes($event)">-->
                  <!--                          </multiselect>-->
                  <!--                          <span class="form-text text-muted" style="text-align:end">-->
                  <!--                                                    {{ $t('type_at_least_three_letters') + ' ' + $t('debits.tax') }}.-->
                  <!--                                                </span>-->
                  <!--                        </td>-->
                  <td>
                    <div class="d-flex">
                      <select name="" id="discount_type" @change="setValue(index)" v-model="row.discount_type" class="custom-select">
                        <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <input v-model="row.discount_value" @input="setValue(index)" type="number" class="form-control">
                    </div>
                  </td>
                  <td v-if="isApplyTaxesInvoice">{{ row.amount_tax }}</td>
                  <!--                        <td>{{ row.subtotal_before_tax }}</td>-->
                  <!--                        <td>{{ row.subtotal }}</td>-->
                  <td>{{ row.subtotal_before_discount }}</td>
                  <td>{{ row.subtotal_after_discount }}</td>
                  <td>
                    <v-icon style="color: #dc3545;" class="m-40-4" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <!--                      <tr>-->
                <!--                        <td class="border-0" colspan="7"></td>-->
                <!--                        <th colspan="2">{{ $t('debits.debit_subtotal') }}</th>-->
                <!--                        <th colspan="2"><span>{{ data.debit_subtotal ? data.debit_subtotal : '0' }} {{currency_code}}</span></th>-->
                <!--                        <th></th>-->
                <!--                      </tr>-->
                <tr>
                  <td :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                  <th colspan="3"><span>{{ data.total_amount_before_discount ? data.total_amount_before_discount : '0' }} {{ currency_code }}</span></th>
                </tr>
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                  <th colspan="3"><span>{{ data.total_amount_after_discount ? data.total_amount_after_discount : '0' }} {{ currency_code }}</span></th>
                </tr>
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_discount') }}</th>
                  <th colspan="3"><span>{{ data.debit_discount ? data.debit_discount : '0' }} {{ currency_code }}</span></th>
                </tr>
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_after_shipping') }}</th>
                  <th colspan="3"><span>{{ data.debit_shipping ? data.debit_shipping : '0' }} {{ currency_code }}</span></th>
                </tr>
                <tr v-if="isApplyTaxesInvoice">
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('debits.total_tax') }}</th>
                  <th colspan="3"><span>{{ data.debit_taxes ? data.debit_taxes : '0' }} {{ currency_code }}</span></th>
                </tr>
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.final_total') }} <i class="fa fa-exclamation-circle ml-2 mr-2" v-b-tooltip.hover.leftbottom :title="$t('sales_invoices.total_amount_before_discount') + ' - ' + $t('sales_invoices.total_discount') + ' + ' + $t('sales_invoices.total_after_shipping') + ' + ' + $t('sales_invoices.total_tax')"></i></th>
                  <th colspan="3"><span>{{ data.debit_total ? data.debit_total : '0' }} {{ currency_code }}</span></th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <b-tabs content-class="mt-3" class="nav-custom-link">
            <b-tab :title="$t('discount_and_settlement')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('debits.discount_type') }}</label>
                    <select name="" id="discount_types" v-model="data.discount_type" class="custom-select" :class="validation && validation.discount_type ? 'is-invalid' : ''">
                      <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                    </select>
                    <span v-if="validation && validation.discount_type" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.discount_type[0] }}
                                </span>
                  </div>
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('debits.discount_value') }}</label>
                    <input type="number" v-model="data.discount_value" class="form-control" :class="validation && validation.discount_value ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.discount_value" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.discount_value[0] }}
                                </span>
                  </div>

                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('debits.adjustment') }}</label>
                    <input type="number" v-model="data.adjustment" class="form-control" :class="validation && validation.adjustment ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.adjustment" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.adjustment[0] }}
                                    </span>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('delivery_information')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('debits.shipping_details') }}</label>
                    <select name="" id="shipping_details" v-model="data.shipping_details" class="custom-select" :class="validation && validation.shipping_details ? 'is-invalid' : ''">
                      <option v-for="row in shipping_details_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                    </select>
                    <span v-if="validation && validation.shipping_details" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.shipping_details[0] }}
                            </span>
                  </div>
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('debits.shipping_fees') }}</label>
                    <input type="number" @input="calcItemListForTotal" v-model="data.shipping_fees" class="form-control" :class="validation && validation.shipping_fees ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.shipping_fees" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.shipping_fees[0] }}
                            </span>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('deposit')">
              <div class="card card-custom">
                <div class="card-body row">
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('notes')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('debits.notes') }}</label>
                    <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                    <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>


        </div>

      </b-tab>
      <b-tab :title="$t('debits.attachments')" :disabled="!idEditing" @click="toggleTab('attachments')">
        <div class="card card-custom mt-5">
          <div class="card-body">
            <attachments :sales-debit-id="idEditing"></attachments>
          </div>
        </div>
      </b-tab>
      <b-tab v-if="idEditing" :title="$t('activity_log.activity_log')" @click="toggleTab('activity_log')">
        <div class="card card-custom mt-5">
          <div class="card-body">
            <br>
            <activity-log :inner-key="key" :id="idEditing"></activity-log>
          </div>
        </div>
      </b-tab>
    </b-tabs>


    <div class="pl-0 pr-0" v-if="tab_name != 'activity_log'">
      <div class="row">
        <div class="col-lg-6 mt-10">
          <button type="reset" class="btn btn-primary mr-2" @click="save($event)">{{ $t('save') }}</button>
          <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="modal" size="lg" hide-footer :title="$t('customers.add_new_customer')">
      <customer-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewCustomer"></customer-form>
    </b-modal>
    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Attachments from "./Attachments";
import ItemForm from "@/view/content/forms/ItemForm";
import CustomerForm from "@/view/content/forms/CustomerForm";
import ActivityLog from "@/view/content/widgets/details/ActivityLog";
import {mapGetters, mapState} from "vuex";
import dataTypeNumbers from '@/core/config/mix/dataTypeNumbers';
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";
export default {
  name: "form-debits",
  components: {'attachments': Attachments, 'item-form': ItemForm, 'customer-form': CustomerForm, 'activity-log': ActivityLog},
  data() {
    return {
      mainRoute: 'sales/sales_debits',
      mainRouteDependency: 'base/dependency',
      mainTaxSettingsRoute: 'sales/tax_setting_sales',
      key: 'SalesDebit',

      mainRouteSalesInvoice: 'sales/general_sales',

      data: {
        debit_code: null,
        debit_subtotal: 0,
        debit_total: 0,
        debit_discount: 0,
        debit_shipping: 0,
        debit_taxes: 0,
        total_amount_before_discount: 0,
        total_amount_after_discount: 0,
        shipping_details: null,
        shipping_fees: null,
        adjustment: null,
        customer_id: null,
        discount_type: 1,
        discount_value: null,
        currency_id: null,
        status: 1,
        debit_date: null,
        notes: null,
        branch_id: null,
        // exchange_rate: null,
        // deposite_value: null,
        // payment_terms: null,
        debit_issue: null,
        // inventory_id: null,
        account_id: null,
        sale_invoice_id: this.$route.params.sale_invoice_id ? this.$route.params.sale_invoice_id : null,
        apply_tax_rules: [],
        is_general: 1,
        is_apply_taxes_debit: false,
        total_out_tax: 0,
        total_tax_type_val: [],
      },
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      // inventory: null,

      accounts: [],
      account: null,
      customers: [],
      currencies: [],
      items: [],
      taxes: [],
      units: [],
      inventories: [],
      branches: [],
      branch: null,
      shipping_details_list: [],
      status_list: [],
      isDisabled: false,
      // shipping_details_list: [
      // {id: 0, name: this.$t('shipping_details.auto')},
      // {id: 1, name: this.$t('shipping_details.dont_show')},
      // {id: 2, name: this.$t('shipping_details.show_main_client_details')},
      // {id: 3, name: this.$t('shipping_details.show_secondary_client_details')},
      // ],
      tax_default: null,
      unit_default: null,
      validation: null,
      customer: null,
      items_list_form: {id: null, description: null, unit_price: null, qty: null, unit: null, tax: null, discount_type: null, discount_value: null, subtotal: null, amount_tax: null, subtotal_before_tax: null, tax_percentage: null, subtotal_before_discount: null, subtotal_after_discount: null, tax_applied: [], amount_excluded_tax: 0, total_item_after_discount_with_excluded: null, general_sales_item_id: null, qty_basic: null},
      items_list: [],
      discount_types: [
        {id: 1, name: '-'},
        {id: 2, name: '%'},
        {id: 3, name: this.$t('discount_types.value')},
      ],
      tab_name: 'basic_information',
      currency_code: null,
      taxes_rules_for_tax_setting: [],
      tax_settings: [],
      is_allow_tax_calc: false,
      items_list_hide: [],
      repeater_validation: [],
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),
    isApplyTaxesInvoice() {
      let _status = this.data.is_apply_taxes_debit;
      // if (_status){
      //   this.setTaxForEveryItem()
      // }else{
      //   this.removeTaxFromEveryItem()
      // }
      // this.calcItemListForTotal();
      return _status;
    },
  },
  watch: {
    account: function (val) {
      if (val) {
        this.data.account_id = val.id;
      }
    },
    // items_list: {
    //   handler(val) {
    //     if (val.length > 0) {
    //       this.calcItemListForTotal();
    //     }
    //   },
    //   deep: true
    // },
    '$route'(val) {
      if (val.params.id) {
        this.idEditing = val.params.id;
        this.getData();
      }
    },
    customer: function (val) {
      if (val) {
        this.data.customer_id = val.id;
      } else {
        this.data.customer_id = null;
      }
    },
    'data.customer_id': function (val) {
      if (val) {
        let _cu = this.customers.filter(row => row.id == val);
        if (_cu[0]) {
          _cu = _cu[0]
          if (this.isDisabled == false) {
            // this.data.currency_id = _cu.currency_id;
            if (_cu.currency_id) {
              this.data.currency_id = _cu.currency_id;
            } else {
              this.getDefaultCurrency();
            }
          }
        }
      }
    },
    // inventory: function (val) {
    //   if (val) {
    //     this.data.inventory_id = val.id;
    //   } else {
    //     this.data.inventory_id = null;
    //   }
    // },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      }
    },
    "data.currency_id": function (val) {
      if (val) {
        let _currency = this.currencies.find((row) => row.id == val);
        if (_currency) {
          this.currency_code = _currency.code;
        } else {
          this.currency_code = null;
        }
      } else {
        this.currency_code = null;
      }

      // if (val) {
      //   this.getExchangeRate(val);
      // } else {
      //   this.data.exchange_rate = null;
      // }
    },
    "data.shipping_fees": function (val) {
      if (val) {
        if (String(val).length > 12) {
          this.data.shipping_fees = String(val).slice(0, 12);
        }
      }
    },
  },
  methods: {
    ...dataTypeNumbers,
    ...timeZoneStructure,
    validationQTYChecking(_status = true) {
      let _main_validation_status = false;
      this.validation = [];
      this.items_list.forEach((row, index) => {
        let _validation_status = false;

        // if (parseFloat(row.qty) <= 0) {
        //   this.validation[`items_list.${index}.qty`] = [];
        //   this.validation[`items_list.${index}.qty`][0] = this.$t('quantity_must_be_greater_than_0');
        //   _validation_status = true;
        // } else if (!row.qty && _status) {
        //   this.validation[`items_list.${index}.qty`] = [];
        //   this.validation[`items_list.${index}.qty`][0] = this.$t('quantity_must_be_greater_than_0');
        //   _validation_status = true;
        // }
        if (parseFloat(row.unit_price) <= 0) {
          this.validation[`items_list.${index}.unit_price`] = [];
          this.validation[`items_list.${index}.unit_price`][0] = this.$t('the_unit_price_field_must_be_required');
          _validation_status = true;
        } else if (!row.unit_price && _status) {
          this.validation[`items_list.${index}.unit_price`] = [];
          this.validation[`items_list.${index}.unit_price`][0] = this.$t('the_value_must_not_be_less_than_0');
          _validation_status = true;
        }

        if(!row.description && _status){
          this.validation[`items_list.${index}.description`] = [];
          this.validation[`items_list.${index}.description`][0] = this.$t('the_description_field_must_be_required');
          _validation_status = true;
        }
        if(!row.unit && _status){
          this.validation[`items_list.${index}.unit`] = [];
          this.validation[`items_list.${index}.unit`][0] = this.$t('the_unit_field_must_be_required');
          _validation_status = true;
        }

        // if (parseFloat(row.amount_tax) <= 0 && row.tax_applied.length > 0) {
        //   _validation_status = true;
        // }else if(!row.amount_tax && _status && row.tax_applied.length > 0){
        //   _validation_status = true;
        // }
        //
        // if (parseFloat(row.subtotal_before_discount) <= 0) {
        //   _validation_status = true;
        // }else if(!row.subtotal_before_discount && _status){
        //   _validation_status = true;
        // }


        if (_validation_status && _status){
          let _index = this.repeater_validation.indexOf(index);
          if (_index < 0) {
            this.repeater_validation.push(index);
          }
        }else{
          let _index = this.repeater_validation.indexOf(index);
          if (_index > -1) {
            this.repeater_validation.splice(_index, 1);
          }
        }

      });
      if (this.repeater_validation.length > 0){
        _main_validation_status = true;
      }
      if (_main_validation_status){
        let _items = [...this.items_list];
        this.items_list = [];
        this.items_list = _items;
        if (_status)
          this.$errorAlertMessage(this.$t('unable_to_send_due_to_data_entry_error'));
      }
      return _main_validation_status;

    },

    save(event) {
      if (this.validationQTYChecking()) {
        event.preventDefault();
        return false;
      } else {
        if (this.isEditing) {
          this.update();
        } else {
          this.create();
        }
      }
    },

    create() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => parseFloat(row.qty) > 0);

      ApiService.post(`${this.mainRoute}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            // this.$router.push({name: 'general-debits.edit', params: {id: response.data.data.id}});
            this.$router.push({name: 'general-debits.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => parseFloat(row.qty) > 0);

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'general-debits.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    getClassValidationRepeaterByIndex(index) {
      if (index != null) {
        if (this.repeater_validation.includes(index))
            // return 'tr-validation-error';
          return 'border-validation';
      }
      return '';
    },

    async getData() {
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.debit_code = response.data.data.debit_code;
        this.data.sale_invoice_id = response.data.data.sale_invoice_id;
        this.data.debit_subtotal = response.data.data.debit_subtotal;
        this.data.debit_total = response.data.data.debit_total;
        this.data.debit_discount = response.data.data.debit_discount;
        this.data.debit_shipping = response.data.data.debit_shipping;
        this.data.debit_taxes = response.data.data.debit_taxes;

        this.data.total_out_tax = response.data.data.total_out_tax ? response.data.data.total_out_tax : 0;
        this.data.total_tax_type_val = response.data.data.total_tax_type_val ? response.data.data.total_tax_type_val : [];

        this.data.total_amount_before_discount = response.data.data.total_amount_before_discount;
        this.data.total_amount_after_discount = response.data.data.total_amount_after_discount;
        this.data.shipping_details = response.data.data.shipping_details;
        this.data.shipping_fees = response.data.data.shipping_fees;
        this.data.adjustment = response.data.data.adjustment;
        this.data.customer_id = response.data.data.customer_id;
        this.data.discount_type = response.data.data.discount_type;
        this.data.discount_value = response.data.data.discount_value;
        this.data.currency_id = response.data.data.currency_id;
        this.data.status = response.data.data.status;
        this.data.debit_date = response.data.data.debit_date;
        this.data.debit_issue = response.data.data.debit_issue;
        this.data.notes = response.data.data.notes;
        // this.data.inventory_id = response.data.data.inventory_id;
        // this.inventory = response.data.data.inventory;
        this.customer = response.data.data.customer;
        // this.data.exchange_rate = response.data.data.exchange_rate;
        this.data.account_id = response.data.data.account_id;
        this.account = response.data.data.account;
        this.data.apply_tax_rules = response.data.data.apply_tax_rules;
        this.data.is_apply_taxes_debit = response.data.data.is_apply_taxes_debit;

        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;
        if (!response.data.data.account_id) {
          this.getDefaultAccount();
        }

        this.items_list = response.data.data.items_list;

        if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
          this.addItemRowToList();
        }
      });
    },
    // async getSalesInvoiceData() {
    //   if (this.data.sale_invoice_id) {
    //     await ApiService.get(`${this.mainRouteSalesInvoice}/${this.data.sale_invoice_id}`).then((response) => {
    //
    //       this.data.total_amount_before_discount = response.data.data.total_amount_before_discount;
    //       this.data.total_amount_after_discount = response.data.data.total_amount_after_discount;
    //
    //       this.data.debit_subtotal = response.data.data.debit_subtotal;
    //       this.data.debit_total = response.data.data.invoice_total - response.data.data.invoice_total_shipping;
    //       this.data.debit_total = this.data.debit_total ? (this.data.debit_total).toFixed(2) : 0;
    //           // this.data.debit_discount = response.data.data.invoice_total_discount;
    //       // this.data.debit_shipping = response.data.data.invoice_total_shipping;
    //       this.data.debit_taxes = response.data.data.invoice_total_taxes;
    //
    //
    //       this.data.customer_id = response.data.data.customer_id;
    //       this.data.currency_id = response.data.data.currency_id;
    //       this.data.inventory_id = response.data.data.inventory_id;
    //       this.inventory = response.data.data.inventory;
    //       this.customer = response.data.data.customer;
    //       this.items_list = response.data.data.items_list.map((row) => {
    //         // row.unit = this.unit_default;
    //         row.discount_type = 1;
    //         row.discount_value = 0;
    //         row.subtotal_before_discount = row.subtotal_after_discount;
    //         row.unit_price = row.subtotal_after_discount ? (parseFloat(row.subtotal_after_discount) / row.qty).toFixed(2)  : 0;
    //         return row;
    //       });
    //
    //       if (this.data.sale_invoice_id && response.data.data.customer_id && response.data.data.inventory_id) {
    //         this.isDisabled = true;
    //       }
    //       this.calcItemListForTotal();
    //     });
    //   }
    // },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    async getCustomers(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    // getTaxes(filter) {
    //   if (filter && filter.length >= 3) {
    //     ApiService.get(`${this.mainRouteDependency}/taxes?type=4`, {params: {filter: filter}}).then((response) => {
    //       this.taxes = response.data.data;
    //     });
    //   } else {
    //     this.taxes = [];
    //   }
    // },
    getTaxes() {
      ApiService.get(`${this.mainRouteDependency}/taxes?type=4`).then((response) => {
        this.taxes = response.data.data;
      });
    },
    getUnits() {
        ApiService.get(`${this.mainRouteDependency}/units`).then((response) => {
          this.units = response.data.data;
        });

    },

    // async getDefaultTax() {
    //   await ApiService.get(`${this.mainRouteDependency}/default-tax`).then((response) => {
    //     this.tax_default = response.data.data;
    //   });
    // },


    getShippingDetails() {
      ApiService.get(this.mainRouteDependency + "/shipping_details").then((response) => {
        this.shipping_details_list = response.data.data;
      });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status", {params: {type: 'debits'}}).then((response) => {
        this.status_list = response.data.data;
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;

      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },

    addItemRowToList(is_fetch_all = false) {
      // this.items_list_form.tax = this.tax_default;
      if (is_fetch_all) {
        let _items = this.items_list_hide.map((row) => {
          row.qty = 0;
          return {...row};
        });
        this.items_list = [..._items];
        this.calcItemListForTotal();
      } else {
        // this.items_list_form.unit = this.unit_default;
        this.items_list.unshift(this.items_list_form);
        this.items_list_form = {id: null, item: null, description: null, unit_price: null, qty: null, tax: null, discount_type: null, discount_value: null, subtotal: null, amount_tax: null, subtotal_before_tax: null, unit: null, tax_applied: [], amount_excluded_tax: 0, total_item_after_discount_with_excluded: null, general_sales_item_id: null, qty_basic: null};
      }
    },
    removeItemRowFromList(index = 0) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
      }
      this.calcItemListForTotal();
    },

    selectItem(index) {
      this.items_list[index].unit_price = this.items_list[index].item ? this.items_list[index].item.purchase_price : 0;
      this.items_list[index].tax_percentage = this.items_list[index].item ? this.items_list[index].item.total_taxes : 0;
      // this.items_list[index].tax_applied = (this.items_list[index].item && this.items_list[index].item.taxes) ? this.items_list[index].item.taxes : [];

      this.setValue(index);
    },
    // setValue(index) {
    //   this.items_list[index].subtotal = (this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0);
    // },
    setValue(index) {
      this.zeroForNegative();
      let promise = this.getItemTax(index);
      Promise.all([promise]).then(() => {
        let _tax = 0;
        if (this.items_list[index].unit) {
          if (this.items_list[index].unit.parent_id) {
            this.items_list[index].qty = (this.items_list[index].unit.sub_value_total ? this.items_list[index].unit.sub_value_total : 0) * (this.items_list[index].units_number ? this.items_list[index].units_number : 0);
          } else {
            this.items_list[index].units_number = 0;
          }
        }


        let _subtotal = (this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0);
        this.items_list[index].subtotal_before_tax = (_subtotal).toFixed(2);

        let _discount = 0;
        if (this.items_list[index].discount_type == 1 || !this.items_list[index].discount_type) {
          _discount = 0;
        } else {
          _discount = ((this.items_list[index].discount_type == 2 && this.items_list[index].discount_value) ? (parseFloat(this.items_list[index].discount_value) / 100) * _subtotal : (this.items_list[index].discount_value ? parseFloat(this.items_list[index].discount_value) : 0));
        }

        /**
         *
         * calc data subtotal before discount  = unit price * quantity
         * calc data subtotal after discount  = (unit price * quantity) - discount
         */
        this.items_list[index].subtotal_before_discount = _subtotal;
        this.items_list[index].subtotal_after_discount = _subtotal - _discount;


        // _subtotal = _subtotal - _discount;
        // let _tax = _subtotal * (this.items_list[index].tax_percentage ? parseFloat(this.items_list[index].tax_percentage) / 100 : 0);

        /**
         * calculation for taxes
         */


// this is used to get tax setting value
//       let _tax_rule_value = null;
//       let _tax_rule_id = (this.data.apply_tax_rules && this.data.apply_tax_rules[0]) ? this.data.apply_tax_rules[0].tax_rule_id : null;
//       if (_tax_rule_id) {
//         let _tax_rule = this.taxes_rules_for_tax_setting.find((row) => row.id == _tax_rule_id);
//         let _tax_rule_details = _tax_rule.tax_rule_details[0];
//         _tax_rule_value = _tax_rule_details ? _tax_rule_details.value : null;
//       }


        // dependant on value calculate the tax
        // if (this.items_list[index] && this.items_list[index].tax) {
        //   //console.log(this.items_list[index].tax);
        //   let _tax_percentage_include_tax = this.items_list[index].tax ? this.items_list[index].tax.include_tax : 0;
        //
        //   let _tax_percentage_first = this.items_list[index].tax ? this.items_list[index].tax.value_rate : 0;
        //   _tax_percentage_first = _tax_percentage_first ? parseFloat(_tax_percentage_first) : 0;
        //
        //   let _tax_first = 0;
        //   if (_tax_percentage_include_tax == 0) {
        //     _tax_first = this.items_list[index].unit_price - (this.items_list[index].unit_price / (1 + (_tax_percentage_first / 100)));
        //     _tax += (_tax_first * (this.items_list[index].qty ? this.items_list[index].qty : 1));
        //   } else {
        //     if (_tax_rule_value == 1) {
        //       _tax_first = _subtotal * (_tax_percentage_first / 100);
        //       _tax += _tax_first;
        //     } else if (_tax_rule_value == 2) {
        //       _tax_first = (_subtotal - _discount) * (_tax_percentage_first / 100);
        //       _tax += _tax_first;
        //     }
        //   }
        //   _subtotal = _subtotal - _tax_first;
        // }

        this.items_list[index].total_item_after_discount_with_excluded = this.items_list[index].subtotal_after_discount + this.items_list[index].amount_tax;


        // this.items_list[index].amount_tax = (_tax).toFixed(2);
        this.items_list[index].subtotal_before_discount = this.items_list[index].subtotal_before_discount ? (this.items_list[index].subtotal_before_discount).toFixed(2) : 0;
        this.items_list[index].subtotal_after_discount = this.items_list[index].subtotal_after_discount ? (this.items_list[index].subtotal_after_discount).toFixed(2) : 0;
        this.items_list[index].subtotal = (_subtotal - _discount + _tax).toFixed(2);
        this.calcItemListForTotal();
      })
    },


    zeroForNegative() {
      this.items_list = this.items_list.map((row) => {
        if (String(row.unit_price).length > 12) {
          row.unit_price = String(row.unit_price).slice(0, 12);
        }
        if (String(row.qty).length > 9) {
          row.qty = String(row.qty).slice(0, 9);
        }
        if(String(row.discount_value).length > 9){
          row.discount_value = String(row.discount_value).slice(0, 9);
        }


        if (isNaN(row.unit_price)) {
          row.unit_price = 1;
        } else if (parseFloat(row.unit_price) < 1) {
          row.unit_price = 1;
        }
        if (isNaN(row.qty)) {
          row.qty = 0;
        } else if (parseFloat(row.qty) < 0) {
          row.qty = 0;
        }

        if ((row.discount_type == 1 || !row.discount_type) && (row.discount_value != 0 && row.discount_value != null)) {
          row.discount_type = 2;
        }
        if (isNaN(row.discount_value)) {
          row.discount_value = 0;
        }else if (parseFloat(row.discount_value) < 0) {
          row.discount_value = 0;
        }

        if (row.discount_type == 2) {
          if (parseFloat(row.discount_value) > 100) {
            row.discount_value = 100;
          }
        } else if (row.discount_type == 3) {
          if (parseFloat(row.discount_value) > parseFloat(row.subtotal_before_discount)) {
            row.discount_value = row.subtotal_before_discount;
          }
        }


        if (isNaN(row.tax_percentage) || !Number(row.tax_percentage)) {
          row.tax_percentage = 0;
        }
        if (parseFloat(row.tax_percentage) < 0) {
          row.tax_percentage = 0;
        }
        if (parseFloat(row.tax_percentage) > 100) {
          row.tax_percentage = 100;
        }
        return row;

      });
    },
    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },
    getDataAfterCreateNewCustomer(object) {
      let promise = this.getCustomers();
      Promise.all([promise]).then(() => {
        this.data.customer_id = object.id;
      })
    },
    getDataAfterCreateNewItem(object) {
      // let promise = this.getItems();
      // Promise.all([promise]).then(() => {
      if (object) {
        this.items_list[0].item = object;
        this.items_list[0].unit_price = object.sale_price;
        this.items_list[0].tax_percentage = object.total_taxes ? object.total_taxes : 0;
        // this.items_list[0].tax = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
      }
      // })
    },
    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/22").then((response) => {
        this.data.debit_code = response.data.data.code;
      });
    },

    // getAccounts() {
    //     ApiService.get(this.mainRouteDependency + `/account_parents`,{params:{checkDisabled: 1,key:'sales_debit'}}).then((response) => {
    //         this.accounts = response.data.data;
    //     });
    // },
    getAccounts(filter) {
      if (filter && filter.length >= 3)
        return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts = response.data.data;
        });
    },
    getDefaultAccount() {
      ApiService.get(`${this.mainRouteDependency}/get_default_account/sales_debit`).then((response) => {
        this.account = response.data.data;
      });
    },

    loadOptions() {
    },
    updateValue(value) {
      this.data.account_id = value;
    },

    // getExchangeRate(id) {
    //   ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
    //     this.data.exchange_rate = response.data.data.initial_val;
    //   });
    // },
    calcItemListForTotal() {
      this.data.debit_shipping = this.data.shipping_fees;

      if (this.items_list.length > 0) {

        // this.data.debit_subtotal = this.$_.sumBy(this.items_list, (row) => {
        //   return row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
        // });
        /**
         * sum amount before discount
         */
        this.data.total_amount_before_discount = this.$_.sumBy(this.items_list, (row) => {
          return (row.subtotal_before_discount ? parseFloat(row.subtotal_before_discount) : 0);
        });

        /**
         * sum amount after discount
         */
        this.data.total_amount_after_discount = this.$_.sumBy(this.items_list, (row) => {
          return (row.subtotal_after_discount ? parseFloat(row.subtotal_after_discount) : 0);
        });


        this.data.debit_subtotal = this.$_.sumBy(this.items_list, (row) => {
          return ((row.unit_price ? row.unit_price : 0) * (row.qty ? row.qty : 0));
        });

        let _discount_value = this.$_.sumBy(this.items_list, (row) => {
          if (!row.discount_type || row.discount_type == 1) {
            return 0;
          }
          // let _subtotal = row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
          let _subtotal = ((row.unit_price ? row.unit_price : 0) * (row.qty ? row.qty : 0));
          return ((row.discount_type == 2 && row.discount_value) ? (parseFloat(row.discount_value) / 100) * _subtotal : (row.discount_value ? parseFloat(row.discount_value) : 0));

        });

        this.data.debit_discount = _discount_value;
        this.data.debit_shipping = (this.data.debit_shipping ? parseFloat(this.data.debit_shipping) : 0);


        this.data.debit_taxes = this.$_.sumBy(this.items_list, (row) => {
          return row.amount_tax ? parseFloat(row.amount_tax) : 0;
        });

        let _amount_excluded_tax = this.$_.sumBy(this.items_list, (row) => {
          return row.amount_excluded_tax ? parseFloat(row.amount_excluded_tax) : 0;
        });

        /**
         * summation tax dependent on  include_tax
         * if be 1 used to sum
         * another not used
         */
        // let _amount_excluded_tax = this.$_.sumBy(this.items_list, (row) => {
        //   return row.amount_excluded_tax ? parseFloat(row.amount_excluded_tax) : 0;
        // });
        // let _total_tax_included_tax = this.$_.sumBy(this.items_list, (row) => {
        //   let _arr_include_taxes = row.include_taxes;
        //   let _include_tax = null
        //   if (_arr_include_taxes && _arr_include_taxes[0]) {
        //     _include_tax = _arr_include_taxes[0];
        //   }
        //   if (_include_tax && _include_tax.include_tax == 1) {
        //     return row.amount_tax ? parseFloat(row.amount_tax) : 0;
        //   }
        //   return 0;
        // });

        this.data.total_out_tax = this.$_.sumBy(this.items_list, (row) => {
          if(!row.tax_applied || row.tax_applied.length == 0){
            if (row.unit_price && row.qty){
              return parseFloat(row.unit_price) *  parseFloat(row.qty);
            }
          }
          return 0;
        });

        this.data.debit_total = (this.data.debit_subtotal + _amount_excluded_tax + this.data.debit_shipping) - _discount_value;


        this.data.debit_subtotal = this.data.debit_subtotal ? this.data.debit_subtotal.toFixed(2) : 0;
        this.data.debit_discount = this.data.debit_discount ? this.data.debit_discount.toFixed(2) : 0;
        this.data.debit_shipping = this.data.debit_shipping ? this.data.debit_shipping.toFixed(2) : 0;
        this.data.debit_taxes = this.data.debit_taxes ? this.data.debit_taxes.toFixed(2) : 0;
        this.data.debit_total = this.data.debit_total ? this.data.debit_total.toFixed(2) : 0;
        this.data.total_out_tax = this.data.total_out_tax ? this.data.total_out_tax.toFixed(2) : 0;
        this.data.total_amount_before_discount = this.data.total_amount_before_discount ? this.data.total_amount_before_discount.toFixed(2) : 0;
        this.data.total_amount_after_discount = this.data.total_amount_after_discount ? this.data.total_amount_after_discount.toFixed(2) : 0;
      }
    },
    setTotalTaxTypeVal(){
      this.data.total_tax_type_val = [];
      this.items_list.forEach((row)=>{
        if(row.tax_applied && row.tax_applied.length > 0){
          row.tax_applied.forEach((_row)=>{
            let index_tax = this.data.total_tax_type_val.findIndex(it => _row.tax_id == it.tax_id);
            if (index_tax >= 0){
              this.data.total_tax_type_val[index_tax].tax_value =  (this.data.total_tax_type_val[index_tax].tax_value ? parseFloat(this.data.total_tax_type_val[index_tax].tax_value) : 0)+ (row.tax_value ? parseFloat(row.tax_value) : 0);
              this.data.total_tax_type_val[index_tax].amount_applied =  (this.data.total_tax_type_val[index_tax].amount_applied ? parseFloat(this.data.total_tax_type_val[index_tax].amount_applied) : 0)+ (row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0);
            }else{
              this.data.total_tax_type_val.push({tax_id: _row.tax_id, tax_value: _row.tax_value, amount_applied: row.subtotal_before_tax});
            }
          });

        }
      });
    },
    /**
     * this function used
     * invoice_id and item_id and quantity
     */
    // async getItemTax(data, index) {
    //   let _data = {};
    //   if (this.data.sale_invoice_id) {
    //     _data = {
    //       qty: data.qty,
    //       item_id: data.item_id,
    //       sales_invoice_id: this.data.sale_invoice_id,
    //     };
    //   } else {
    //     _data = {
    //       qty: data.qty,
    //       item_id: data.item_id,
    //       customer_id: this.data.customer_id,
    //     };
    //   }
    //
    //   await ApiService.query(this.mainRouteDependency + "/tax_calculation_sales_debits", {
    //     ..._data,
    //   }).then((response) => {
    //     if (response.data.data.price_per_unit) {
    //       this.items_list[index].unit_price = response.data.data.price_per_unit;
    //     }
    //     this.items_list[index].amount_tax = (response.data.data.tax_value).toFixed(2);
    //     this.items_list[index].discount = (response.data.data.discount).toFixed(2);
    //     this.items_list[index].include_taxes = response.data.data.include_taxes;
    //     this.calcItemListForTotal();
    //   });
    //
    // },
    async getTaxesRulesForTaxSetting() {
      await ApiService.get(this.mainRouteDependency + "/taxes_rules_for_tax_setting/1").then((response) => {
        this.taxes_rules_for_tax_setting = response.data.data;
        response.data.data.forEach((row) => {
          if (row.auto_apply) {
            this.data.apply_tax_rules.push({tax_rule_id: row.id});
          }
        })

      });
    },
    getTaxSettings() {
      ApiService.get(this.mainTaxSettingsRoute).then((response) => {
        this.tax_settings = response.data.data;
        this.is_allow_tax_calc = response.data.data.is_apply_tax_debit;
        this.data.is_apply_taxes_debit = response.data.data.is_apply_tax_debit;

      });
    },
    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
        // this.data.inventory_id = response.data.data.inventory_id;
        // this.inventory = response.data.data.inventory;
        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;
        // this.unit_default = response.data.data.unit;
        // this.items_list_form.unit = response.data.data.unit;
        this.items_list_form.tax = response.data.data.tax;
        if (this.items_list[0]) {
          // this.items_list[0].unit = response.data.data.unit;
          this.items_list[0].tax = response.data.data.tax;
        }
      });
    },
    async getSalesInvoiceData() {
      if (this.data.sale_invoice_id) {
        await ApiService.get(`${this.mainRouteSalesInvoice}/${this.data.sale_invoice_id}`).then((response) => {

          this.data.total_amount_before_discount = response.data.data.total_amount_before_discount;
          this.data.total_amount_after_discount = response.data.data.total_amount_after_discount;

          this.data.customer_id = response.data.data.customer_id;
          this.data.currency_id = response.data.data.currency_id;

          this.customer = response.data.data.customer;

          this.data.is_apply_taxes_debit = response.data.data.is_apply_taxes_invoice;

          this.items_list_hide = response.data.data.items_list.map((row) => {
            row.qty_basic = row.qty;
            row.general_sales_item_id = row.id;

            row.discount_type = 1;
            row.discount_value = 0;
            row.subtotal_before_discount = 0;
            row.unit_price = row.subtotal_after_discount ? (parseFloat(row.subtotal_after_discount) / row.qty).toFixed(2) : 0;
            row.subtotal_after_discount = 0;
            row.amount_tax = 0;
            row.amount_excluded_tax = 0;
            row.data_validation = [];

            return row;
          });

          if (this.data.sale_invoice_id && response.data.data.customer_id) {
            this.isDisabled = true;
          }
          this.calcItemListForTotal();
        });
      }
    },
    async getItemTax(index) {
      // if(this.isApplyTaxesInvoice) {
      let item_id = this.items_list[index].general_sales_item_id ? this.items_list[index].general_sales_item_id : null;
      let quantity = this.items_list[index].qty ? parseFloat(this.items_list[index].qty) : null;
      // let unit_price = this.items_list[index].unit_price ? this.items_list[index].unit_price : null;
      let discount = this.items_list[index].discount_value ? this.items_list[index].discount_value : null;
      let discount_type = this.items_list[index].discount_type ? this.items_list[index].discount_type : null;

      if (item_id && parseFloat(quantity) > 0)
        await ApiService.get(`/v2/items/sale/general/${this.data.sale_invoice_id}/debit/calculate-subtotal`,
            {
              params: {
                item_id: item_id, quantity: (quantity).toFixed(3),
                discount: discount, discount_type: discount_type, row_index: index
              }
            }).then((response) => {
          this.items_list[response.data.data.row_index].amount_tax = response.data.data.tax_total ? this.parseToShortFloat(response.data.data.tax_total) : 0;
          this.items_list[response.data.data.row_index].tax_applied = response.data.data.applied_taxes;
          // this.items_list[response.data.data.row_index].unit_price = response.data.data.unit_price ? this.parseToShortFloat(response.data.data.unit_price) : 0;
          this.items_list[response.data.data.row_index].amount_excluded_tax = response.data.data.amount_excluded_tax ? this.parseToShortFloat(response.data.data.amount_excluded_tax) : 0;
          this.items_list[response.data.data.row_index].data_validation = {tax_total: response.data.data.tax_total, subtotal_before_tax: response.data.data.sub_total};


          this.calcItemListForTotal();

        });
      // }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.general_sales_debits_management"), route: '/sales/general-debits'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    this.getTaxesRulesForTaxSetting();
    this.getTaxSettings();
    this.getCurrencies();
    this.getTaxes();


    this.getUnits();
    this.getShippingDetails();
    // this.getCustomers();
    this.getStatus();
    // this.getInventories();

    this.getBranches();
    this.getAccounts();
    if (!this.data.account_id) {
      this.getDefaultAccount();
    }


    if (this.idEditing) {
      this.getData();
    } else {
      let _promise_1 = this.defaultDataForUser();

      // this.data.debit_date = new Date().toISOString().slice(0, 10);
      // this.data.debit_issue = new Date().toISOString().slice(0, 10);

      this.getTimeZone().then((res)=>{
        this.data.debit_date = res;
        this.data.debit_issue = res;
      });
      this.getCode();

      Promise.all([_promise_1]).then(() => {
        if (this.data.sale_invoice_id) {
          let promise = this.getSalesInvoiceData();

          Promise.all([promise]).then(() => {
            if (this.items_list.length <= 0) {
              this.addItemRowToList();
            }
          });
        } else {
          if (this.items_list.length <= 0) {
            this.addItemRowToList();
          }
        }

      });

    }

  },
};
</script>
